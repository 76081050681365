<template>
  <v-container class="d-flex justify-center align-center">
    <v-card class="form-auth-card" max-width="850" width="100%">
      <v-img
        class="ml-auto mr-auto"
        max-width="327"
        max-height="100"
        height="100%"
        width="100%"
        :src="logoImage"
      ></v-img>
      <v-card-text>
        <v-form @submit.prevent="handleLogin" ref="form">
          <v-text-field
            v-model="email"
            placeholder="メールアドレス"
            type="email"
          ></v-text-field>
          <v-text-field
            v-model="password"
            placeholder="パスワード"
            type="password"
          ></v-text-field>
          <v-alert v-if="errors.length > 0">
            <p v-for="(error, index) in errors" :key="index">・{{ error }}</p>
          </v-alert>
          <v-btn
            class="btn-submit color-2"
            type="submit"
            color="primary"
            block
            :loading="loading"
            :disabled="loading"
          >
            ログイン
            <i class="icon fa-solid fa-circle-chevron-right"></i>
          </v-btn>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          class="btn-redirect"
          text
          :href="urlForgetPassword"
          target="_blank"
          >＞ログインできない場合</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script lang="ts">
import Vue from "vue";
import { login } from "@/services/auth";
import { mapActions, mapState } from "vuex";
import { AxiosError } from "axios";
import logoImage from "@/assets/images/common/logo.png";
import { URL_FORGET_PASSWORD } from "@/constants";
import { b64EncodeUnicode } from "@/services/helper";

export default Vue.extend({
  data() {
    return {
      email: "",
      password: "",
      loading: false,
      role: null,
      errors: [] as string[],
      logoImage: logoImage,
      urlForgetPassword: URL_FORGET_PASSWORD,
    };
  },
  methods: {
    ...mapActions(["login"]),
    async handleLogin() {
      this.errors = [];
      if (!this.email) {
        this.errors.push("メールアドレスは必須です");
      }
      if (this.email && !/.+@.+\..+/.test(this.email)) {
        this.errors.push(
          "メールアドレスを正しい形式のメールアドレスを指定してください。"
        );
      }
      if (!this.password) {
        this.errors.push("パスワードは必須です");
      }
      if (this.errors.length > 0) {
        return;
      }
      this.loading = true;
      try {
        const data = await login(this.email, this.password);
        this.login(data).then(() => {
          if (data.user) {
            const encodedPassword = b64EncodeUnicode(this.password);
            localStorage.setItem("BASE_IFRAME", encodedPassword);
          }
          this.$router.push({ name: "HomePage" });
        });
      } catch (error) {
        const err = error as AxiosError;
        const errorMessage = err.response?.data?.error ?? "";
        const errorMailMessage = err.response?.data?.email ?? "";
        const errorPasswordMessage = err.response?.data?.password ?? "";
        let errorMessages = [];
        errorMessages.push(
          ...errorMailMessage,
          ...errorPasswordMessage,
          ...errorMessage
        );

        this.errors.push(
          ...(errorMessages || [
            "メールアドレスまたはパスワードが間違っています",
          ])
        );
      } finally {
        this.loading = false;
      }
    },

    goToForgotPassword() {
      this.$router.push("/forgot-password");
    },
  },
  computed: {
    ...mapState({
      // eslint-disable-next-line
      user: (state: any) => state.user,
    }),
  },
});
</script>

<style scoped lang="scss">
@import "@/assets/scss/components/card";
</style>
