<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "App",
});
</script>

<style>
.toasted {
  min-width: 300px !important;
  font-family: "Noto Sans", sans-serif !important;
}
</style>
