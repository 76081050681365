<template>
  <div class="nav-bar">
    <div class="logo">
      <a href="/">
        <v-img width="143px" :src="logoImage"></v-img>
      </a>
    </div>
    <div class="d-flex">
      <nav>
        <v-btn :href="questionAwayUrl" target="_blank" variant="text">
          よくある質問
        </v-btn>
      </nav>
      <!-- <v-btn class="bar" @click="dialog = true">
        <i class="fa-solid fa-bars"></i>
      </v-btn> -->

      <v-menu offset-y left rounded="0" class="drop-down-user">
        <template v-slot:activator="{ on, attrs }">
          <button class="btn-menu" dark v-bind="attrs" v-on="on">
            <i class="fa-solid fa-bars"></i>
          </button>
        </template>
        <v-list>
          <v-list-item class="drop-down-user__box" @click="handleLogout">
            <v-list-item-title>ログアウト</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card class="menu-sp" tile>
        <v-toolbar flat>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-list dense>
            <v-list-item-group>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>サービス内容</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>SOZOWスクールについて</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>良くある質問</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>コース</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script lang="ts">
import Vue from "vue";
import logoImage from "@/assets/images/common/logo.png";
import iconUser from "@/assets/images/common/icon-user.svg";
import { mapActions } from "vuex";
import { URL_QUESTION_AWAY } from "@/constants";

export default Vue.extend({
  data() {
    return {
      dialog: false,
      logoImage: logoImage,
      iconUser: iconUser,
      loading: false,
      questionAwayUrl: URL_QUESTION_AWAY,
    };
  },
  methods: {
    ...mapActions(["logout"]),

    async handleLogout() {
      await this.logout();
      localStorage.removeItem("BASE_IFRAME");
      this.$router.push("/login");
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/assets/scss/components/navbar";
</style>
