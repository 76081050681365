import { render, staticRenderFns } from "./MyPageChildren.vue?vue&type=template&id=bcd0e262&scoped=true"
import script from "./MyPageChildren.vue?vue&type=script&lang=ts"
export * from "./MyPageChildren.vue?vue&type=script&lang=ts"
import style0 from "./MyPageChildren.vue?vue&type=style&index=0&id=bcd0e262&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bcd0e262",
  null
  
)

export default component.exports