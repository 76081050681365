<template>
  <v-main>
    <MainLayout>
      <router-view></router-view>
    </MainLayout>
  </v-main>
</template>
<script lang="ts">
import Vue from "vue";
import MainLayout from "@/components/layout/MainLayout.vue";

export default Vue.extend({
  components: {
    MainLayout,
  },
});
</script>
