import apiClient from "../plugins/axios";

/**
 * Fetches children information for MyPageParent.
 *
 * @return {Promise<any>} The response data if status is 200, otherwise null
 */
export async function store(formData: object) {
  const response = await apiClient.post("/users/logs/store", formData);

  if (response && response.status === 200) {
    return response.data;
  }

  return null;
}
