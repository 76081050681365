import apiClient from "../plugins/axios";

export async function login(email: string, password: string) {
  const response = await apiClient.post("/auth/login", { email, password });
  return response.data;
}

export async function forgotPassword(email: string) {
  const response = await apiClient.post("/api/forgot-password", { email });
  return response.data;
}

export async function resetPassword(data: {
  email: string;
  password: string;
  password_confirmation: string;
  token: string;
}) {
  const response = await apiClient.post("/api/reset-password", data);
  return response.data;
}

export async function logout() {
  const response = await apiClient.post("/auth/logout");
  return response.data;
}

export async function getUserAuth() {
  try {
    const response = await apiClient.get("/auth/user");
    const userData = response.data;
    return userData;
  } catch (e) {
    return null;
  }
}
