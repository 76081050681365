<template>
  <div>
    <base-information :formatStudentInfor="studentInfor"></base-information>
    <section v-if="!isEnrollment" class="section-welcome">
      <div class="welcome-wrapper">
        <h2 class="welcome-title">SOZOWスクールへようこそ！</h2>
        <p class="welcome-content">
          ご入学いただきありがとうございます。<br />
          SOZOWでは、お子さまが自分のペースを守れる「安心感」を一番大切にしております。<br />
          お子さまのペースを第一に徐々に活動を広げていけるようサポートしてまいります。
        </p>
      </div>
    </section>
    <section v-if="isEnrollment" class="section-wrap section-news">
      <div class="section-head">
        <h2 class="section-title">お知らせ</h2>
      </div>
      <div class="section-content">
        <div v-html="notificationGuardianPage"></div>
      </div>
    </section>
    <instruction
      v-if="!admissionRegistrationCompleted"
      :isEnrollment="isEnrollment"
      :isZibuntanq="isZibuntanq"
      :beforeEnrollment="beforeEnrollmentInfor"
      :afterEnrollment="afterEnrollmentInfor"
    ></instruction>
    <section v-if="!isEnrollment" class="section-wrap section-schedule">
      <div class="section-head">
        <h2 class="section-title">今後のスケジュール</h2>
      </div>
      <div class="schedule-content">
        <div v-html="admissionMonthSchedule"></div>
        <div class="mt-5">
          <p>
            その他ご不明な点などございましたら<a
              target="_blank"
              class="text-decoration-none"
              :href="urlContact"
              >お問い合わせ窓口</a
            >までご連絡お待ちしております。
          </p>
        </div>
      </div>
    </section>
    <section v-if="isEnrollment" class="section-wrap section-schedule">
      <div class="section-head">
        <h2 class="section-title">今後のイベント</h2>
      </div>
      <div class="section-content">
        <div v-html="admissionEventPage"></div>
        <br />
        <button-link
          :isEnrollment="isEnrollment"
          :url="urlNextEvent"
          :text="'今後のイベントを詳しくみる'"
        ></button-link>
      </div>
    </section>
    <section v-if="isEnrollment" class="section-wrap section-schedule">
      <div class="section-head">
        <h2 class="section-title">お子さまの活動記録</h2>
      </div>
      <div class="section-content mb-3">
        <iframe
          width="100%"
          height="2900"
          :src="urlIframe"
          frameborder="0"
          style="border: 0"
          allowfullscreen
          sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
        ></iframe>
      </div>
    </section>
    <section v-if="isEnrollment" class="section-link">
      <div class="section-head">
        <small>LINK</small>
        <h2 class="section-title">保護者さまページ</h2>
      </div>
      <div class="section-content">
        <div class="link-list">
          <div class="link-item" v-for="link in links" :key="link.startText">
            <v-btn :href="link.url" target="_blank"
              >{{ link.startText }}<br v-if="link.endText" />
              {{ link.endText }}</v-btn
            >
          </div>
        </div>
      </div>
    </section>
    <contact></contact>
  </div>
</template>
<script lang="ts">
/* eslint-disable */
import Vue from "vue";
import ButtonLink from "@/components/common/ButtonLink.vue";
import iconTaskClose from "@/assets/images/common/task-close.svg";
import iconTaskOpen from "@/assets/images/common/task-open.svg";
import iconArrowHalfRight from "@/assets/images/common/arrow-half-right.svg";
import BaseInformation from "./components/BaseInformation.vue";
import Instruction from "./components/Instruction.vue";
import Contact from "./components/Contact.vue";
import { mapState, mapActions, mapGetters } from "vuex";
import { ROLES } from "../../constants";
import moment from "moment";
import * as constants from "@/constants";
import { b64DecodeUnicode } from "@/services/helper";

export default Vue.extend({
  components: {
    BaseInformation,
    ButtonLink,
    Instruction,
    Contact,
  },
  data() {
    return {
      password: "",
      password_confirmation: "",
      iconTaskClose: iconTaskClose,
      iconTaskOpen: iconTaskOpen,
      iconArrowHalfRight: iconArrowHalfRight,
      isEnrollment: false,
      isZibuntanq: false,
      urlNextEvent: constants.URL_NEXT_EVENT,
      links: [
        {
          startText: "保護者さま",
          endText: "ご案内ページ",
          url: constants.URL_DEAR_PARENTS,
        },
        {
          startText: "スクール生向け",
          endText: "サイト",
          url: constants.URL_FOR_SCHOOL_STUDENTS,
        },
        {
          startText: "すらら利用申請",
          endText: "",
          url: constants.URL_APPLICATION_FOR_SURALA_USAGE,
        },
        {
          startText: "出席認定制度",
          endText: "利用申請",
          url: constants.URL_ATTENDANCE_CERTIFICATION_SYSTEM,
        },
        {
          startText: "コース変更申請",
          endText: "",
          url: constants.URL_COURSE_CHANGE_APPLICATION,
        },
        {
          startText: "休会・退会",
          endText: "問い合わせフォーム",
          url: constants.URL_TEMPORARY_SUSPENSION_WITHDRAWAL,
        },
        {
          startText: "契約書面",
          endText: "",
          url: "",
        },
      ],
      studentInfor: {},
      beforeEnrollmentInfor: {},
      afterEnrollmentInfor: {},
      urlIframe: "",
      domain: "",
      paramIframe: "",
      notificationGuardianPage: "",
      admissionMonthSchedule: "",
      admissionEventPage: "",
      urlContact: constants.URL_CONTACT,
      admissionRegistrationCompleted: false,
    };
  },

  methods: {
    ...mapActions({
      fetchChildrenInformation: "users/fetchChildrenInformationForMyPageParent",
      logout: "logout",
    }),

    async handleLogout() {
      await this.logout();
      localStorage.removeItem("BASE_IFRAME");
      this.$router.push("/login");
    },

    handleError() {
      if (confirm("予期しないエラーが発生しました!")) {
        this.handleLogout();
      }
    },
  },

  computed: {
    ...mapState({
      user: (state: any) => state.user,
      childrenInformation: (state: any) => state.users.user,
    }),
    ...mapGetters({
      formatStudentInfor: "users/formatedUserInfor",
    }),
  },

  mounted() {
    if (this.user.role == ROLES.GUARDIAN) {
      this.fetchChildrenInformation().then(() => {
        const userOverview = this.formatStudentInfor
        this.studentInfor = {
          userName: userOverview.userName,
          nickName: userOverview.nickName,
          email: userOverview.email,
          admissionDate: userOverview.admissionDate,
          classGuide: userOverview.classGuide,
          mentor: userOverview.mentor,
          course: userOverview.course,
          school: userOverview.school,
        };

        if (userOverview.course === '自分探Qコース' || userOverview.course === '自分探Qクラシックコース') {
          this.isZibuntanq = true
        }

        if (
          !userOverview.nickName &&
          !userOverview.userName
        ) {
          this.handleError();
          return;
        }
        this.admissionMonthSchedule =
          this.user.beforeEnrollment.admission_month_schedule;

        this.beforeEnrollmentInfor = {
          admissionFormChecked:
            this.user.beforeEnrollment.admission_form_checked,
          admissionPrepFeeChecked:
            this.user.beforeEnrollment.admission_prep_fee_checked,
          admissionFormDeadline:
            this.user.beforeEnrollment.admission_form_deadline,
          cardRegistrationDeadline:
            this.user.beforeEnrollment.card_registration_deadline,
          contractDocumentUrl: this.user.beforeEnrollment.contract_document_url,
          cardRegistrationUrl: this.user.beforeEnrollment.card_registration_url,
          mentor1on1Date: this.user.beforeEnrollment.mentor_1on1_date,
        };

        // Check completed enrollment 1
        if (this.user.beforeEnrollment.is_before_completed) {
          this.isEnrollment = true;
          const admissionCeremonyDatetime = moment(
            this.user.afterEnrollment.admission_ceremony_datetime
          );
          const admissionCeremonyFutureTime = admissionCeremonyDatetime
            ? admissionCeremonyDatetime.add(1, "hour").format("HH:mm")
            : null;
          this.notificationGuardianPage =
            this.user.afterEnrollment.guardian_page_notification;
          this.admissionEventPage =
            this.user.afterEnrollment.guardian_page_event;

          this.afterEnrollmentInfor = {
            welcomeMeetingChecked:
              this.user.afterEnrollment.welcome_meeting_checked,
            welcomeMeetingDate: this.user.afterEnrollment.welcome_meeting_date,
            welcomeMeetingUrl: this.user.afterEnrollment.welcome_meeting_url,
            slackRegistrationChecked:
              this.user.afterEnrollment.slack_registration_checked,
            slackRegistrationEmailDate:
              this.user.afterEnrollment.slack_registration_email_date,
            firstMentor1on1Checked:
              this.user.afterEnrollment.first_mentor_1on1_checked,
            firstMentor1on1MeetingDate:
              this.user.afterEnrollment.first_mentor_1on1_meeting_date,
            firstMentor1on1MeetingUrl:
              this.user.afterEnrollment.first_mentor_1on1_meeting_url,
            admissionCeremonyDatetime:
              this.user.afterEnrollment.admission_ceremony_datetime,
            admissionCeremonyFutureTime: admissionCeremonyFutureTime,
            admissionCeremonyChecked:
              this.user.afterEnrollment.admission_ceremony_checked,
            admissionCeremonyUrl:
              this.user.afterEnrollment.admission_ceremony_url,
            admissionCeremonyMeetingId:
              this.user.afterEnrollment.admission_ceremony_meeting_id,
            admissionCeremonyPass:
              this.user.afterEnrollment.admission_ceremony_pass,
            mentorChecked: userOverview.mentor === "準備中" ?? false,
            currentPlan: userOverview.course ?? "",
            school: userOverview.school ?? "",
            email: userOverview.email ?? "",
            pass: b64DecodeUnicode(userOverview.pass ?? ""),
            minecraftPass: userOverview.minecraftPass ?? "",
          };

          // Check completed enrollment 2
          if (
            this.user.afterEnrollment.welcome_meeting_checked &&
            this.user.afterEnrollment.slack_registration_checked &&
            this.user.afterEnrollment.first_mentor_1on1_checked &&
            this.user.afterEnrollment.admission_ceremony_checked
          ) {
            this.admissionRegistrationCompleted = true;
          }

          const contractDocument = this.links.find(
            (link) => link.startText === "契約書面"
          );
          if (contractDocument) {
            contractDocument.url =
              this.user.beforeEnrollment.contract_document_url ?? "";
          }
        }
      });
    }

    const baseIframe = localStorage.getItem("BASE_IFRAME");

    if (baseIframe) {
      const params = {
        passwordForCoinCredit: baseIframe,
        passwordFor30sec: baseIframe,
        passwordForCoinLogs: baseIframe,
        passwordForReportCommentSummary: baseIframe,
        passwordForSlackVotes: baseIframe,
        passwordForMetalife: baseIframe,
      };

      const paramString = `?params=${encodeURIComponent(
        JSON.stringify(params)
      )}`;

      this.urlIframe = `${constants.URL_IFRAME}${paramString}`;
    }
  },
});
</script>

<style scoped lang="scss">
@import "@/assets/scss/pages/mypage";
</style>
./MyPageParent.vue
