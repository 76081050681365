<template>
  <v-main class="color-1">
    <AuthLayout>
      <router-view></router-view>
    </AuthLayout>
  </v-main>
</template>
<script lang="ts">
import Vue from "vue";
import AuthLayout from "@/components/layout/AuthLayout.vue";

export default Vue.extend({
  components: {
    AuthLayout,
  },
});
</script>
