<template>
  <v-main>
    <router-view></router-view>
    <app-footer></app-footer>
  </v-main>
</template>
<script lang="ts">
import Vue from "vue";
import AppFooter from "@/components/layout/AppFooter.vue";

export default Vue.extend({
  name: "MainLayout",
  components: {
    AppFooter,
  },
});
</script>
