<!-- src/components/ResetPassword.vue -->

<template>
  <v-container class="container d-flex justify-center align-center">
    <v-card class="form-auth-card" max-width="850" width="100%">
      <v-img
        class="ml-auto mr-auto"
        max-width="327"
        max-height="100"
        height="100%"
        width="100%"
        :src="logoImage"
      ></v-img>
      <v-form @submit.prevent="handleResetPassword">
        <v-text-field
          placeholder="メールアドレス"
          required
          disabled
        ></v-text-field>
        <v-text-field
          v-model="password"
          placeholder="パスワード"
          type="password"
          required
        ></v-text-field>
        <v-text-field
          v-model="password_confirmation"
          placeholder="パスワード(確認)"
          type="password"
          required
        ></v-text-field>
        <v-btn class="btn-submit color-2" block type="submit">更新</v-btn>
      </v-form>
    </v-card>
  </v-container>
</template>

<script lang="ts">
import Vue from "vue";
import { resetPassword } from "../../services/auth";
import logoImage from "@/assets/images/common/logo.png";

export default Vue.extend({
  props: {
    token: {
      type: String,
      required: true,
    },
    email: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      password: "",
      password_confirmation: "",
      logoImage: logoImage,
    };
  },
  methods: {
    async handleResetPassword() {
      try {
        const data = await resetPassword({
          email: this.email,
          password: this.password,
          password_confirmation: this.password_confirmation,
          token: this.token,
        });
        console.log("Password reset successful:", data);
        this.$router.push("/login"); // Navigate to login after successful reset
      } catch (error) {
        console.error("Password reset error:", error);
      }
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/assets/scss/components/card";
</style>
