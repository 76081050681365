<template>
  <v-expansion-panel>
    <v-expansion-panel-header disable-icon-rotate>
      <div class="d-flex align-center" v-if="iconHidden">
        <span>{{ title }}</span>
      </div>
      <div class="d-flex align-center" v-else>
        <i v-if="iconDisable" class="icon fa-solid fa-circle-check"></i>
        <i v-else class="icon disable fa-solid fa-circle-check"></i>
        <span>{{ title }}</span>
      </div>
      <template v-slot:actions>
        <v-img class="close" :src="iconTaskClose"></v-img>
        <v-img class="open" :src="iconTaskOpen"></v-img>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <template>
        <slot name="boardContent"></slot>
      </template>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script lang="ts">
import Vue from "vue";
import iconTaskClose from "@/assets/images/common/task-close.svg";
import iconTaskOpen from "@/assets/images/common/task-open.svg";

export default Vue.extend({
  props: {
    title: {
      type: String,
      default: "",
    },
    iconDisable: {
      type: Boolean,
      default: true,
    },
    iconHidden: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      iconTaskClose: iconTaskClose,
      iconTaskOpen: iconTaskOpen,
    };
  },
});
</script>
