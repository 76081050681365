import moment from "moment-timezone";

moment.tz.setDefault("Asia/Tokyo");

const MomentPlugin = {
  // eslint-disable-next-line
  install(Vue: any) {
    Vue.prototype.$moment = moment;
  },
};

export default MomentPlugin;
