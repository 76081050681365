import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#1976D2",
        secondary: "#424242",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        "color-1": "#E6E9FF",
        "color-2": "#FFCC00",
        "color-3": "#6688FF",
        "color-4": "#333333",
      },
    },
  },
});
