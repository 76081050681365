export const API_VERSION = "/api";
export const ROLES = {
  GUARDIAN: 1,
  STUDENT: 2,
};

// NavBar
export const URL_QUESTION_AWAY = "https://sozow-school.com/faq";

// AppFooter
export const URL_OPERATING_COMPANY = "https://sozow.com/";
export const URL_TERMS_OF_SERVICE =
  "https://image.sozow.net/img/docs/sozowschool_term.pdf";
export const URL_SPECIFIC_COMMERCIAL_TRANSACTIONS_ACT =
  "https://image.sozow.net/img/docs/tokushoho.pdf";
export const URL_PRIVACY_POLICY =
  "https://image.sozow.net/img/docs/privacy.pdf";
export const URL_CONTACT = "mailto:contact-school@go-visions.com";
export const URL_FACEBOOK = "https://www.facebook.com/sozow.official/";
export const URL_INSTAGRAM = "https://www.instagram.com/sozowschool_official/";
export const URL_X = "https://twitter.com/SOZOW_school";
export const URL_YOUTUBE =
  "https://www.youtube.com/channel/UCgGGtQNBlA8wzZJGuHBj_Hg";

// Login
export const URL_FORGET_PASSWORD =
  "https://form.run/@SOZOW-school-member-contact";

// MyPageChildren
export const URL_META_LIFE =
  "https://app.metalife.co.jp/spaces/iOiD1Ar7spsV2rnqd46Q?respawnFloor=FRbY2IIbdKwau7Cz1z9b";
export const URL_ZOOM =
  "https://zoom.us/j/98756502362?pwd=YzlZdXNyUEJhV0d4SklFT1RnSVNFZz09";

// MyPageParent
export const URL_NEXT_EVENT =
  "https://sites.google.com/go-visions.com/sozow-school/home?authuser=0";
export const URL_DEAR_PARENTS =
  "https://sites.google.com/go-visions.com/sozow-school/hogosya";
export const URL_FOR_SCHOOL_STUDENTS =
  "https://sites.google.com/go-visions.com/sozow-school/home";
export const URL_APPLICATION_FOR_SURALA_USAGE =
  "https://sites.google.com/go-visions.com/sozow-school/hogosya/tsuika?authuser=0";
export const URL_ATTENDANCE_CERTIFICATION_SYSTEM =
  "https://sites.google.com/go-visions.com/sozow-school/hogosya/syussekininntei?authuser=0";
export const URL_COURSE_CHANGE_APPLICATION =
  "https://sites.google.com/go-visions.com/sozow-school/hogosya/coursechange?authuser=0";
export const URL_TEMPORARY_SUSPENSION_WITHDRAWAL =
  "https://sites.google.com/go-visions.com/sozow-school/hogosya/kyutaikai?authuser=0";
export const URL_IFRAME =
  "https://lookerstudio.google.com/embed/reporting/5b308549-2739-4470-95e1-45ae9de4d6e6/page/sTiKD";

// Instruction
export const URL_WEBMAIL = "https://webmail48.onamae.ne.jp/";
export const URL_DOWNLOAD_SLACK =
  "https://slack.com/intl/ja-jp/downloads/windows";
export const URL_MENTOR_ON_DATE = "https://forms.gle/uzKHrRgvfWVAce9W6";
export const URL_MATERIAL =
  "https://sites.google.com/go-visions.com/sozowqfaq/bookrun";
export const URL_MINECRAFT =
  "https://education.minecraft.net/ja-jp/get-started/download";

export const URL_1on1_rules =
  "https://sites.google.com/go-visions.com/sozow-school/for-new-member/about-1on1/about-1on1-rule";
