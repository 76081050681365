<template>
  <v-container class="d-flex justify-center align-center">
    <v-card class="form-auth-card" max-width="850" width="100%">
      <v-img
        class="ml-auto mr-auto"
        max-width="327"
        max-height="100"
        height="100%"
        width="100%"
        :src="logoImage"
      ></v-img>
      <v-card-text>
        <v-alert
          v-if="successMessage"
          type="success"
          dismissible
          @input="successMessage = ''"
        >
          {{ successMessage }}
        </v-alert>
        <v-alert
          v-if="errorMessage"
          type="error"
          dismissible
          @input="errorMessage = ''"
        >
          {{ errorMessage }}
        </v-alert>
        <v-form @submit.prevent="handleForgotPassword" ref="form">
          <v-text-field
            v-model="email"
            placeholder="メールアドレス"
            type="email"
            required
            :rules="emailRules"
          ></v-text-field>
          <v-btn
            class="btn-submit color-2"
            type="submit"
            color="primary"
            block
            :loading="loading"
            :disabled="loading"
          >
            送信する
          </v-btn>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn class="btn-redirect" text @click="goToLogin"
          >＞ログインに戻る</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { forgotPassword } from "@/services/auth";
import { AxiosError } from "axios";
import logoImage from "@/assets/images/common/logo.png";

@Component
export default class ForgotPasswordPage extends Vue {
  email = "";
  loading = false;
  successMessage = "";
  errorMessage = "";
  logoImage = logoImage;

  emailRules = [
    (v: string) => !!v || "メールアドレスは必須です",
    (v: string) =>
      /.+@.+\..+/.test(v) ||
      "メールアドレスを正しい形式のメールアドレスを指定してください。",
  ];

  async handleForgotPassword() {
    this.successMessage = "";
    this.errorMessage = "";
    const form = this.$refs.form as Vue & { validate: () => boolean };

    if (!form.validate()) {
      return;
    }
    this.loading = true;
    try {
      await forgotPassword(this.email);
      this.successMessage = "A reset link has been sent to your email.";
    } catch (error) {
      const err = error as AxiosError;
      this.errorMessage =
        err.response?.data?.message || "Failed to send reset link";
    } finally {
      this.loading = false;
    }
  }

  goToLogin() {
    this.$router.push("/login");
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/components/card";
</style>
