/* eslint-disable */
import { fetchChildrenInformationForMyPageParent } from "../../services/user";

interface State {
  user: any;
}

const state: State = {
  user: null,
};

const mutations = {
  setUserInformation(state: State, user: any) {
    state.user = user;
  },
};

const actions = {
  async fetchChildrenInformationForMyPageParent({ commit }: any) {
    const response = await fetchChildrenInformationForMyPageParent();

    if (response) {
      commit("setUserInformation", response.data);
    }
  },
};

function formatUserInfor(user: any) {
  if (user) {
    const userName = user.user_name;
    const nickName = user.nickname;
    const admissionDate = user.admission_season;
    const mentor = user.name_staff;
    const classGuide = user.class_guide;
    const course = user.current_plan;
    const school = user.member_id;
    const pass = user.pass;
    const minecraftPass = user.minecraft_pass;
    const email = user.email;

    return {
      userName,
      nickName,
      admissionDate,
      mentor,
      classGuide,
      course,
      school,
      pass,
      minecraftPass,
      email,
    };
  }

  return {};
}

const getters = {
  formatedUserInfor: (state: State) => {
    return formatUserInfor(state.user);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
