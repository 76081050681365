import Vue from "vue";
import "./plugins/axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "@fortawesome/fontawesome-free/css/all.css";
import Toasted from "./plugins/toast";
import Moment from "./plugins/moment-timezone";
import Filters from "./utils/filters/filter";

Vue.config.productionTip = false;
Vue.use(Toasted);
Vue.use(Filters);
Vue.use(Moment);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
