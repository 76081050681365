<template>
  <v-btn
    class="btn-link"
    :href="url"
    target="_blank"
    :disabled="isDisabled"
    @click="onClick"
    ><span class="title-button">{{ text }}</span
    ><img class="icon" :src="iconButton"
  /></v-btn>
</template>
<script lang="ts">
import Vue from "vue";
import iconArrowHalfRight from "@/assets/images/common/arrow-half-right.svg";

export default Vue.extend({
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
    url: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: iconArrowHalfRight,
    },
  },
  data() {
    return {
      iconButton: this.icon,
    };
  },
  methods: {
    onClick() {
      this.$emit("click");
    },
  },
});
</script>
