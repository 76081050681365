import Vue from "vue";
import Vuex from "vuex";
import { logout } from "../services/auth";
import users from "./module/user";
import { getUserAuth } from "../services/auth";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    users,
  },
  state: {
    user: null,
    role: null,
    access_token: null,
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
      state.role = user?.role;
    },
    setAccessToken(state, accessToken) {
      state.access_token = accessToken;
    },
  },
  actions: {
    login({ commit }, data) {
      localStorage.setItem("AUTH_ACCESS_TOKEN", data.access_token);
      commit("setAccessToken", data.access_token);
      commit("setUser", data.user);
    },
    async logout({ commit }) {
      try {
        await logout();
      } catch (e) {
        console.log(e);
      }
      localStorage.removeItem("AUTH_ACCESS_TOKEN");
      commit("setAccessToken", null);
      commit("setUser", null);
    },
    async checkLogin({ commit }) {
      commit("setAccessToken", localStorage.getItem("AUTH_ACCESS_TOKEN"));
      if (localStorage.getItem("AUTH_ACCESS_TOKEN")) {
        const authUser = await getUserAuth();
        if (authUser !== null) {
          commit("setUser", authUser);
        } else {
          localStorage.removeItem("AUTH_ACCESS_TOKEN");
          commit("setAccessToken", null);
          commit("setUser", null);
        }
      }
    },
  },
  getters: {
    isAuthenticated: (state) => !!state.user,
    user: (state) => state.user,
    role: (state) => state.role,
  },
});
