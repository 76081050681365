<template>
  <v-main class="auth-main color-1">
    <router-view></router-view>
    <app-footer></app-footer>
  </v-main>
</template>
<script lang="ts">
import Vue from "vue";
import AppFooter from "@/components/layout/AppFooter.vue";

export default Vue.extend({
  name: "AuthLayout",
  components: {
    AppFooter,
  },
});
</script>

<style lang="scss" scoped>
.auth-main {
  padding-top: 100px !important;
}
</style>
