import Vue, { PluginFunction } from "vue";
import store from "@/store";
import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError,
} from "axios";
import { API_VERSION } from "@/constants";

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;

const config: AxiosRequestConfig = {
  baseURL: (process.env.VUE_APP_API_URL || "") + API_VERSION,
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
};

const _axios: AxiosInstance = axios.create(config);

_axios.interceptors.request.use(
  function (config: AxiosRequestConfig): AxiosRequestConfig {
    const token = store.state.access_token || "";
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    // Do something before request is sent
    return config;
  },
  function (error: AxiosError): Promise<AxiosError> {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function (response: AxiosResponse): AxiosResponse {
    // Do something with response data
    return response;
  },
  function (error: AxiosError): Promise<AxiosError> {
    // Do something with response error
    return Promise.reject(error);
  }
);

interface AxiosPlugin {
  install: PluginFunction<AxiosInstance>;
}

const Plugin: AxiosPlugin = {
  install(_Vue: typeof Vue): void {
    _Vue.prototype.$axios = _axios;
  },
};

Vue.use(Plugin);

declare module "vue/types/vue" {
  interface Vue {
    $axios: AxiosInstance;
  }
}

export default _axios;
