<template>
  <v-footer class="color-4">
    <v-row justify="center" no-gutters>
      <v-btn
        v-for="link in links"
        :key="link.title"
        class="link mx-2 color-4"
        color="white"
        variant="text"
        :href="link.url"
        target="_blank"
      >
        {{ link.title }}
      </v-btn>
      <v-col cols="12">
        <div class="social d-flex justify-center mt-4">
          <v-btn :href="urlX" target="_blank" class="mx-2">
            <i class="fa-brands fa-x-twitter"></i>
          </v-btn>
          <v-btn class="mx-2" :href="urlInstagram" target="_blank">
            <i class="fa-brands fa-instagram"></i>
          </v-btn>
          <v-btn class="mx-2" :href="urlYouTube" target="_blank">
            <i class="fa-brands fa-youtube"></i>
          </v-btn>
        </div>
      </v-col>
      <v-col cols="12">
        <a href="/">
          <v-img
            class="logo mx-auto mt-5 mb-5"
            width="300px"
            :src="LogoFooter"
          ></v-img>
        </a>
      </v-col>
      <v-col class="copyright text-center mt-4" cols="12">
        COPYRIGHT © SOZOW Co., Ltd. ALL RIGHTS RESERVED.
      </v-col>
    </v-row>
  </v-footer>
</template>

<script lang="ts">
import Vue from "vue";
import LogoFooter from "@/assets/images/common/logo-footer.webp";
import * as constants from "@/constants";

export default Vue.extend({
  data() {
    return {
      LogoFooter,
      links: [
        { title: "運営企業", url: constants.URL_OPERATING_COMPANY },
        {
          title: "利用規約",
          url: constants.URL_TERMS_OF_SERVICE,
        },
        {
          title: "特定商取引法に基づく表記",
          url: constants.URL_SPECIFIC_COMMERCIAL_TRANSACTIONS_ACT,
        },
        {
          title: "プライバシーポリシー",
          url: constants.URL_PRIVACY_POLICY,
        },
        {
          title: "お問い合わせ",
          url: constants.URL_FORGET_PASSWORD,
        },
      ],
      urlX: constants.URL_X,
      urlInstagram: constants.URL_INSTAGRAM,
      urlYouTube: constants.URL_YOUTUBE,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/footer.scss";
</style>
