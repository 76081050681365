<template>
  <section class="section-search color-1">
    <nav-bar></nav-bar>
    <div class="form-search">
      <div class="head-title flex-shrink-0">
        <svg
          class="icon-file-lines"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M896.152 336.108l0 576.229c0 26.511-21.508 48.019-48.018 48.019L175.866 960.356c-26.511 0-48.019-21.508-48.019-48.019L127.847 112.019c0-26.511 21.508-48.019 48.019-48.019l448.179 0c26.512 0 63.527 15.508 82.031 34.013l156.064 156.064C880.645 272.582 896.152 309.597 896.152 336.108zM832.127 384.127 624.045 384.127c-26.512 0-48.02-21.508-48.02-48.019L576.025 128.025 191.873 128.025l0 768.306 640.254 0L832.127 384.127zM335.93 448.153l352.14 0c9.004 0 16.006 7.003 16.006 16.006l0 32.013c0 9.003-7.002 16.007-16.006 16.007L335.93 512.179c-9.003 0-16.006-7.003-16.006-16.007l0-32.013C319.924 455.156 326.927 448.153 335.93 448.153zM704.076 592.21l0 32.013c0 9.004-7.002 16.007-16.006 16.007L335.93 640.23c-9.003 0-16.006-7.003-16.006-16.007L319.924 592.21c0-9.003 7.003-16.006 16.006-16.006l352.14 0C697.074 576.204 704.076 583.207 704.076 592.21zM704.076 720.262l0 32.013c0 9.003-7.002 16.006-16.006 16.006L335.93 768.281c-9.003 0-16.006-7.003-16.006-16.006l0-32.013c0-9.004 7.003-16.007 16.006-16.007l352.14 0C697.074 704.255 704.076 711.258 704.076 720.262zM640.051 320.102l188.076 0c-3.002-8.505-7.502-17.009-11.002-20.511L660.563 143.029c-3.502-3.501-12.006-8-20.512-11.003L640.051 320.102z"
          />
        </svg>
        <span>基本情報</span>
      </div>
      <div class="search-body w-100">
        <v-row class="px-5">
          <v-col sm="3" cols="6">
            <v-select
              label="お子様のお名前"
              :value="formatStudentInfor.userName"
              readonly
              :items="[formatStudentInfor.userName]"
            ></v-select>
          </v-col>
          <v-col sm="3" cols="6">
            <v-select
              label="スクールID"
              readonly
              :value="formatStudentInfor.school"
              :items="[formatStudentInfor.school]"
            ></v-select>
          </v-col>
          <v-col sm="3" cols="6">
            <v-select
              label="ニックネーム"
              readonly
              :value="formatStudentInfor.nickName"
              :items="[formatStudentInfor.nickName]"
            ></v-select>
          </v-col>
          <v-col sm="3" cols="6">
            <div class="input-icon-wrapper">
              <v-select
                label="入学日"
                readonly
                :value="formatStudentInfor.admissionDate"
                :items="[formatStudentInfor.admissionDate]"
              ></v-select>
            </div>
          </v-col>
        </v-row>
        <v-row class="px-5">
          <!-- <v-col sm="3" cols="6">
            <v-select
              label="クラスガイド"
              readonly
              :value="formatStudentInfor.classGuide"
              :items="[formatStudentInfor.classGuide]"
            ></v-select>
          </v-col> -->
          <v-col sm="6" cols="6">
            <v-select
              label="メンター"
              readonly
              :value="formatStudentInfor.mentor"
              :items="[formatStudentInfor.mentor]"
            ></v-select>
          </v-col>
          <v-col sm="6" cols="12">
            <v-select
              label="契約コース"
              readonly
              :value="formatStudentInfor.course"
              :items="[formatStudentInfor.course]"
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="px-5">
          <v-col sm="6" cols="12">
            <v-select
              label="メールアドレス"
              readonly
              :value="formatStudentInfor.email"
              :items="[formatStudentInfor.email]"
            ></v-select>
          </v-col>
        </v-row>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import "@/assets/scss/pages/components/base_information";
</style>
<script lang="ts">
import Vue from "vue";
import NavBar from "@/components/common/NavBar.vue";

export default Vue.extend({
  props: {
    formatStudentInfor: {
      required: false,
      default: {},
    },
  },
  components: {
    NavBar,
  },
});
</script>
