<template>
  <section class="section-wrap section-instruct">
    <div class="section-head">
      <h2 class="section-title">入学までの準備</h2>
      <b>画面の指示に従って、入学に必要な準備を進めてください。</b>
    </div>
    <div class="instruct-list">
      <v-expansion-panels multiple accordion>
        <struction-board
          v-if="!isEnrollment"
          :iconDisable="beforeEnrollment.admissionPrepFeeChecked"
          :title="'クレジットカード登録'"
        >
          <template v-slot:boardContent>
            <hr />
            <p class="mb-5">
              学費お支払いのため、クレジットカードのご登録をお願いします。<br />※クレジットカード以外の支払いをご希望の場合は別途ご連絡ください。
            </p>
            <p class="mb-5">
              所要時間：5分程度<br />
              登録締切：<span v-if="beforeEnrollment.cardRegistrationDeadline">
                {{ beforeEnrollment.cardRegistrationDeadline | datetimeFormat }}
              </span>
              <span v-else>M月 DD日（曜日）23:59</span> <br />
              ※クレジットカードの登録で入学申込み完了とさせていただきます。<br />
              お支払い額：クレジットカード登録リンク先でご確認ください。<br />
              お支払い日：クレジットカード登録時 ※2ヶ月目以降は当月1日払い<br />
            </p>
            <p class="mb-5">
              登録前に<a
                :href="beforeEnrollment.contractDocumentUrl"
                target="_blank"
                >概要書面</a
              >をご確認ください。
            </p>
            <button-link
              :isDisabled="beforeEnrollment.admissionPrepFeeChecked"
              :url="beforeEnrollment.cardRegistrationUrl"
              :text="'クレジットカード登録リンク'"
            ></button-link>
          </template>
        </struction-board>
        <struction-board
          v-if="!isEnrollment"
          :iconDisable="beforeEnrollment.admissionFormChecked"
          :title="'入学準備フォームの回答'"
        >
          <template v-slot:boardContent>
            <hr />
            <p class="mb-5">
              保護者さま・お子さまの現在の様子や希望を知り、SOZOWスクールにて個別最適な関わりをしていくためのフォームです。
            </p>
            <p class="mb-5">
              所要時間：30分程度<br />
              登録締切：<span v-if="beforeEnrollment.admissionFormDeadline">{{
                beforeEnrollment.admissionFormDeadline | datetimeFormat
              }}</span
              ><span v-else>M月DD日（曜日）23:59</span><br />
              お子様のサポートやメンターとのマッチングでも活用するアンケートとなります。<br />
              なお、メンターとの1on1は<span
                v-if="beforeEnrollment.mentor1on1Date"
                >{{ beforeEnrollment.mentor1on1Date | monthFormat }}</span
              ><span v-else>M月</span
              >からとなり、ご入学初月は1on1に変わるコンテンツを提供いたします。
            </p>
            <button-link
              :isDisabled="beforeEnrollment.admissionFormChecked"
              :text="'入学準備フォーム'"
              :url="urlMentorOnDate"
            ></button-link>
          </template>
        </struction-board>
        <struction-board
          v-if="isEnrollment"
          :title="'入学式への参加'"
          :iconDisable="afterEnrollment.admissionCeremonyChecked"
        >
          <template v-slot:boardContent>
            <hr />
            <p class="mb-5">
              Zoomにて入学式を開催します。<br />
              保護者様もご一緒に参加いただけますと幸いですので、<br />
              当日になったら下記のURLをクリックし、ご参加ください。<br />
              <br />
              <span v-if="afterEnrollment.admissionCeremonyDatetime">
                {{
                  afterEnrollment.admissionCeremonyDatetime |
                    datetimeEndTimeFormat
                }}
              </span>
              <span v-else>■ 開催日時：M月D日（曜日）HH:MM〜HH:MM </span><br />
              ■ 対象：保護者さま＋お子さま
            </p>
            <button-link
              :text="'入学式への参加リンク'"
              :isDisabled="afterEnrollment.admissionCeremonyChecked"
              :url="afterEnrollment.admissionCeremonyUrl"
            ></button-link>
            <div class="table-info">
              <dl>
                <dt>ミーティングID：</dt>
                <dd>{{ afterEnrollment.admissionCeremonyMeetingId }}</dd>
              </dl>
              <dl>
                <dt>パスコード：</dt>
                <dd>{{ afterEnrollment.admissionCeremonyPass }}</dd>
              </dl>
            </div>
          </template>
        </struction-board>
        <struction-board
          v-if="isEnrollment"
          :iconDisable="afterEnrollment.welcomeMeetingChecked"
          :title="'ウェルカム面談の予約'"
        >
          <template v-slot:boardContent>
            <hr />
            <p class="mb-5">
              ご入学する皆さまに15分ほどウェルカム面談（保護者さま向け）をさせてただいております。<br />
              面談はスクールスタッフが担当し、ご入学にあたってのご挨拶や入学準備フォームの回答についてのご質問、その他サポートをさせて頂いております。
            </p>
            <p class="mb-5">
              ■ 所要時間：5分程度<br />
              ■ ご予約締切：<span v-if="afterEnrollment.welcomeMeetingDate">{{
                afterEnrollment.welcomeMeetingDate | datetimeFormat
              }}</span
              ><span v-else>M月 DD日（曜日）23:59</span>
              <br />
              ■ 対象：保護者さま＋お子さま<br />
              ■ 面談内容：1ヶ月目の活動内容についてのご案内やご不明点の解消
            </p>
            <p class="mb-5">
              ＜日程調整の流れ＞<br />
              申込み直後・前日・当日30分前にメールをお送りいたします。<br />
              当日はメールに記載のリンクよりご入室ください。
            </p>
            <button-link
              :text="'ウェルカム面談予約リンク'"
              :isDisabled="afterEnrollment.welcomeMeetingChecked"
              :url="afterEnrollment.welcomeMeetingUrl"
            ></button-link>
          </template>
        </struction-board>
        <struction-board
          v-if="isEnrollment"
          :iconDisable="afterEnrollment.slackRegistrationChecked"
          :title="'Slackの準備'"
        >
          <template v-slot:boardContent>
            <hr />
            <p class="mb-5">
              Slackはスクールからのお知らせ配信やスタッフ・スクール生とのコミュニケーションをとるためのチャットツールです。<br />
              所要時間：15分程度
            </p>
            <p class="mb-5">
              ＜登録方法＞<br />
              （1）SOZOWスクールで使用するPCで以下のボタンをクリックする。<br />
              ＊お名前.comをクリックしたら準備開始のチェックがつきます。作業は最後まで完了してください！
            </p>
            <button-link
              class="mb-10"
              :url="urlWebMail"
              :text="'お名前ドットコム（Webメール）'"
            ></button-link>
            <p>（2）以下のメールアドレスとパスワードを入力してログインする。</p>
            <div class="table-info mb-5">
              <dl>
                <dt>メールアドレス：</dt>
                <dd>{{ afterEnrollment.email ?? "xxx@example.com" }}</dd>
              </dl>
              <dl>
                <dt>パスワード：</dt>
                <dd>{{ afterEnrollment.pass }}</dd>
              </dl>
            </div>
            <p>※個人のメールアドレスは使用できません。</p>
            <img class="img-responsive mb-10" :src="imageSlack2" alt="" />
            <p>
              （3）受信箱から件名が「Slackでやり取りするために招待されました」のメールを開く。
            </p>
            <img class="img-responsive mb-10" :src="imageSlack3" alt="" />
            <p>（4）メール内の「今すぐ参加」をクリックする。</p>
            <img class="img-responsive mb-10" :src="imageSlack4" alt="" />
            <p>
              （5）空白内に「ニックネーム＋スクールID」をご入力いただき「続行する」を押す。
            </p>
            <div class="table-info">
              <dl>
                <dt>スクールID:</dt>
                <dd>{{ afterEnrollment.school }}</dd>
              </dl>
            </div>
            <img class="img-responsive mb-10" :src="imageSlack5" alt="" />
            <p>（6）ブックマークに登録する。</p>
            <img class="img-responsive mb-10" :src="imageSlack6" alt="" />
            <p>
              （7）（任意）お子さまのスマートフォン・タブレットにSlackのアプリをダウンロードする。
            </p>
            <p>
              <strong class="mb-5"
                ><span class="pink--text"
                  >スマホ・タブレットをおすすめする理由！</span
                ></strong
              >
              <br />
              <strong class="mb-5">・簡単にアクセス</strong>できる!
              <br />
              <strong>・通知が届く</strong
              >ので、活動スケジュールが把握しやすい！
            </p>
            <button-link
              class="mb-10 mt-5"
              :url="urlDownloadSlack"
              :text="'アプリのダウンロードリンク'"
            ></button-link>
            <p>
              ※お子さまがスマホをお持ちでない方は、保護者さまのスマホでも可能です。<br />
              詳しい使い方については
              <span v-if="afterEnrollment.slackRegistrationEmailDate">{{
                afterEnrollment.slackRegistrationEmailDate |
                  datetimeEndTimeFormat
              }}</span>
              <span v-else> M/DD（曜日）HH:MM〜HH:MM </span>
              「はじめてのスラック」でご案内いたします。
            </p>
          </template>
        </struction-board>
        <struction-board
          v-if="isEnrollment"
          :title="'初回1on1の予約'"
          :iconDisable="afterEnrollment.firstMentor1on1Checked"
        >
          <template v-slot:boardContent>
            <hr />
            <p class="mb-5">
              入学準備フォームの回答内容から、お子さまの担当メンターさんの推薦をさせていただきました。<br />
              今後は担当メンターさんと、固定の曜日・時間に30分ほど1on1（個別面談）ができます。<br />
              初回の面談の日程の調整をお願いします。
            </p>
            <p class="mb-5">
              ■ 準備時間：5分程度<br />
              ■ 対象：お子さま<br />
              ■ 面談内容：お子さまの日々の活動サポート<br />
              ■ 開始日：<span
                v-if="afterEnrollment.firstMentor1on1MeetingDate"
                >{{
                  afterEnrollment.firstMentor1on1MeetingDate | dateFormat
                }}</span
              ><span v-else>M/D（曜日）</span>〜以降で選択ください。<br />
              ※日程調整のやり方がわからない方は、<a
                :href="urlMaterial"
                target="_blank"
                >こちらの資料</a
              >をご覧ください。<br />
              ※1on1のルールは<a :href="url1on1rules" target="_blank"
                >こちらをご覧ください。</a
              >
            </p>
            <p>
              ＜日程調整後の流れ＞
              ご予約後3日以内に担当メンターから、当日の参加URLをご案内いたしますのでお待ちください。
              初回以降は、担当メンターと直接日程調整を行なっていただきます。
            </p>
            <button-link
              :text="'初回1on1予約リンク'"
              :isDisabled="
                afterEnrollment.firstMentor1on1Checked ||
                afterEnrollment.mentorChecked
              "
              :url="afterEnrollment.firstMentor1on1MeetingUrl"
            ></button-link>
            <div v-if="afterEnrollment.mentorChecked" class="none-mentor-text">
              お子様に合ったメンターを探しておりますので、確定までしばらくお待ちください。
            </div>
          </template>
        </struction-board>
        <struction-board
          v-if="isEnrollment"
          v-show="!isZibuntanq"
          :title="'（任意）教育版マインクラフトの準備'"
          :iconHidden="true"
        >
          <template v-slot:boardContent>
            <hr />
            <p class="mb-5">
              SOZOWスクールにはマイクラの活動がたくさんありますので、ぜひ準備をお願いします。
              必ずこちらの専用メールアドレス、パスワードを入力してください。
            </p>
            <button-link
              :text="'マイクラ教育版を準備する'"
              :url="urlMinecraft"
            ></button-link>
            <div class="table-info">
              <dl>
                <dt>メールアドレス：</dt>
                <dd>{{ afterEnrollment.email ?? "xxx@example.com" }}</dd>
              </dl>
              <dl>
                <dt>パスワード：</dt>
                <dd>{{ afterEnrollment.minecraftPass }}</dd>
              </dl>
            </div>
          </template>
        </struction-board>
      </v-expansion-panels>
    </div>
  </section>
</template>
<script lang="ts">
import Vue from "vue";
import ButtonLink from "@/components/common/ButtonLink.vue";
import StructionBoard from "@/components/common/StructionBoard.vue";
import imageStepTwo from "@/assets/images/my_page/step_2.png";
import imageStepThree from "@/assets/images/my_page/step_3.png";
import imageStepFour from "@/assets/images/my_page/step_4.png";
import imageStepFive from "@/assets/images/my_page/step_5.png";
import imageStepSixSukitanq from "@/assets/images/my_page/step_6_sukitanq.png";
import imageStepSixZibuntanq from "@/assets/images/my_page/step_6_zibuntanq.png";
import * as constants from "@/constants";

export default Vue.extend({
  components: {
    ButtonLink,
    StructionBoard,
  },
  props: {
    beforeEnrollment: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    afterEnrollment: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    isEnrollment: {
      type: Boolean,
      required: false,
      default: false,
    },
    isZibuntanq: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      password: "",
      password_confirmation: "",
      urlWebMail: constants.URL_WEBMAIL,
      urlDownloadSlack: constants.URL_DOWNLOAD_SLACK,
      urlMentorOnDate: constants.URL_MENTOR_ON_DATE,
      urlMaterial: constants.URL_MATERIAL,
      urlMinecraft: constants.URL_MINECRAFT,
      url1on1rules: constants.URL_1on1_rules,
      imageSlack2: imageStepTwo,
      imageSlack3: imageStepThree,
      imageSlack4: imageStepFour,
      imageSlack5: imageStepFive,
      imageSlack6: "",
    };
  },
  watch: {
    afterEnrollment: {
      immediate: true,
      handler(newVal) {
        if (newVal && newVal.currentPlan) {
          if (newVal.currentPlan.includes("未来探Q")) {
            this.imageSlack6 = "";
          } else if (newVal.currentPlan.includes("自分探Q")) {
            this.imageSlack6 = imageStepSixZibuntanq;
          } else if (newVal.currentPlan.includes("好き探Q")) {
            this.imageSlack6 = imageStepSixSukitanq;
          }
        }
      },
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/assets/scss/pages/components/instruction";
</style>
