import { VueConstructor } from "vue";
import Toasted, { ToastOptions } from "vue-toasted";

interface ToastObject {
  goAway: (delay: number) => void;
}

export default {
  install(Vue: VueConstructor) {
    Vue.use(Toasted, {
      theme: "bubble",
      position: "top-center",
      duration: 2000,
      action: {
        text: "CLOSE",
        onClick: (e: Event, toastObject: ToastObject) => {
          toastObject.goAway(0);
        },
      },
    } as ToastOptions);
  },
};
