function formatDate(value: string): {
  month: number;
  day: number;
  weekday: string;
  time: string;
} {
  const date = new Date(value);

  const month = date.getMonth() + 1;
  const day = date.getDate();

  const weekdayOptions: Intl.DateTimeFormatOptions = { weekday: "short" };
  const weekday = date.toLocaleDateString("ja-JP", weekdayOptions).charAt(0);

  const timeOptions: Intl.DateTimeFormatOptions = {
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
  };
  const time = date.toLocaleTimeString("ja-JP", timeOptions);

  return {
    month: month,
    day: day,
    weekday: weekday,
    time: time,
  };
}

function datetimeFormat(value: string): string {
  if (!value) return "";

  const date = formatDate(value);
  return `${date.month}月${date.day}日（${date.weekday}）${date.time}`;
}

function dateFormat(value: string): string {
  if (!value) return "";

  const date = formatDate(value);
  return `${date.month}月${date.day}日（${date.weekday}）`;
}

function datetimeEndTimeFormat(value: string): string {
  if (!value) return "";

  const date = new Date(value);
  const startTime = formatDate(value);

  const endDate = new Date(date.getTime() + 60 * 60 * 1000);
  const endTime = endDate.toLocaleTimeString("ja-JP", {
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
  });

  return `${startTime.month}月${startTime.day}日（${startTime.weekday}）${startTime.time}~${endTime}`;
}

function monthFormat(value: string): string {
  if (!value) return "";
  const options: Intl.DateTimeFormatOptions = {
    month: "short",
  };
  const date = new Date(value);
  return date.toLocaleDateString("ja-JP", options);
}

const filters: { [key: string]: (value: string) => string } = {
  datetimeFormat,
  monthFormat,
  datetimeEndTimeFormat,
  dateFormat,
  // Add filters here
};

export default {
  // eslint-disable-next-line
  install(Vue: any) {
    Object.keys(filters).forEach((key) => {
      Vue.filter(key, filters[key]);
    });
  },
};
