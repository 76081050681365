import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import AuthWrapper from "@/components/layout/AuthWrapper.vue";
import MainWrapper from "@/components/layout/MainWrapper.vue";
import Login from "@/views/Auth/Login.vue";
import ForgotPassword from "@/views/Auth/ForgotPassword.vue";
import ResetPassword from "@/views/Auth/ResetPassword.vue";
import MyPageParent from "@/views/MyPage/MyPageParent.vue";
import MyPageChildren from "@/views/MyPage/MyPageChildren.vue";
import store from "../store";
import { ROLES } from "@/constants";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "HomePage",
    component: MainWrapper,
    meta: { requiresAuth: true },
    redirect: () => {
      const userRole = store.getters.role;

      if (userRole === ROLES.GUARDIAN) {
        return { name: "GuardianPage" };
      } else if (userRole === ROLES.STUDENT) {
        return { name: "StudentPage" };
      }
      return { name: "GuardianPage" };
    },
    children: [
      {
        path: "/",
        name: "GuardianPage",
        component: MyPageParent,
        meta: { role: ROLES.GUARDIAN },
      },
      {
        path: "/",
        name: "StudentPage",
        component: MyPageChildren,
        meta: { role: ROLES.STUDENT },
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    meta: { requiresGuest: true },
    component: AuthWrapper,
    children: [
      {
        path: "/",
        meta: { requiresGuest: true },
        component: Login,
      },
    ],
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    meta: { requiresGuest: true },
    component: AuthWrapper,
    children: [
      {
        path: "/",
        meta: { requiresGuest: true },
        component: ForgotPassword,
      },
    ],
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: AuthWrapper,
    meta: { requiresGuest: true },
    children: [
      {
        path: "/",
        props: (route) => ({
          token: route.query.token,
          email: route.query.email,
        }),
        meta: { requiresGuest: true },
        component: ResetPassword,
      },
    ],
  },
  {
    path: "/logout",
    name: "Logout",
    beforeEnter: (to, from, next) => {
      store.dispatch("logout");
      next("/login");
    },
  },
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  await store.dispatch("checkLogin");
  const userRole = store.getters.role;
  if (
    to.matched.some((record) => record.meta.requiresGuest) &&
    store.getters.isAuthenticated
  ) {
    // Redirect authenticated users from guest routes
    return next("/");
  }

  if (
    to.matched.some((record) => !record.meta.requiresGuest) &&
    !store.getters.isAuthenticated
  ) {
    // Redirect unauthenticated users from protected routes
    return next("/login");
  }

  // Check if the route requires a specific role and the user has that role,
  // if not, redirect to the appropriate route based on the user's role.
  if (
    to.matched.some((record) => record.meta.role) &&
    to.meta &&
    to.meta.role !== userRole
  ) {
    if (to.path == "/") {
      if (userRole === ROLES.GUARDIAN) {
        return next({
          name: "GuardianPage",
        });
      } else if (userRole === ROLES.STUDENT) {
        return next({
          name: "StudentPage",
        });
      }
    }
  }

  next();
});

export default router;
