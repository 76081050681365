<template>
  <section class="section-contact">
    <v-card class="contact-card">
      <img class="icon" :src="contactEye" />
      <v-card-title>Contact</v-card-title>
      <v-card-subtitle>お問い合わせ</v-card-subtitle>
      <v-card-text
        >ご不明点やご心配事がございましたらこちらよりお気軽にご相談ください。<br />
        ※お問い合わせいただいた後、3営業日以内に返答します。<br />
        （10時〜18時：土日祝定休）</v-card-text
      >
      <a :href="urlMailTo" target="_blank">フォームから問い合わせる</a>
    </v-card>
  </section>
</template>
<script lang="ts">
import Vue from "vue";
import contactEye from "@/assets/images/common/contact-eye.png";
import { URL_FORGET_PASSWORD } from "@/constants";

export default Vue.extend({
  data() {
    return {
      urlMailTo: URL_FORGET_PASSWORD,
      contactEye: contactEye,
    };
  },
});
</script>
<style lang="scss" scoped>
@import "@/assets/scss/pages/components/contact";
</style>
